<template>
    <div class="login-container">
        <div class="login-containers"></div>
        <div class="login-content">
            <!--            <img src="./../../assets/loginlogo.png" class="login-logo" alt="">-->
            <div class="login-title">URX密室逃脱后台管理系统</div>
            <div class="input-row">
                <div class="icon-content">
                    <i class="iconfont icon-fl-renyuan"></i>
                </div>
                <div class="input-row-label">用户名：</div>
                <input type="text" style="background-color: transparent !important" placeholder="请输入用户名" tabindex="1"
                       v-model="username">
            </div>
            <div class="input-row">
                <div class="icon-content">
                    <i class="iconfont icon-quanxian"></i>
                </div>
                <div class="input-row-label">密码：</div>
                <input type="password" minLength="6" maxLength="20" tabindex="2" @keyup.enter="login"
                       placeholder="请输入密码" v-model="password">
            </div>
            <div class="password-forget cur-p" @click="forget=!forget">
                <div class="select-content" :class="{active: forget}">
                    <i class="iconfont icon-duihao"></i>
                </div>
                <div class="select-content-text">记住密码</div>
            </div>
            <!--<div class="password-forget cur-p">-->

                <!--<div type="success" @click="createUser">注册账号</div>-->
            <!--</div>-->

            <div v-load="loading" @click="login" tabindex="3" ref="loginBtn" class="login-btn">登录</div>
			
        </div>
		<div class="gov">
			<div class="center">
				ICP备：<a href="https://beian.miit.gov.cn" target="_blank">渝ICP备20006713号-1</a>
			</div>
		</div>
        <!-- 用户添加 -->
        <HgDialog title="注册用户" :width="1000" :maskClose="false" v-model="addUser">
            <div v-loading="UserLoading" style="padding: 20px;">
                <el-form ref="form" :model="userAdd" label-width="80px">
                    <el-form-item label="手机号码" required>
                        <el-input v-model="userAdd.username"></el-input>
                    </el-form-item>
                    <el-form-item  required @click="getCode">
                        <el-button type="text"   @click="getCode">获取验证码</el-button>
                    </el-form-item>
                    <el-form-item label="验证码" required>
                        <el-input v-model="userAdd.code"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" required>
                        <el-input v-model="userAdd.password"></el-input>
                    </el-form-item>



                    <el-form-item>
                        <el-button type="primary" @click="adminAddUser">保存</el-button>
                        <el-button @click="offuser">取消</el-button>
                    </el-form-item>
                </el-form>

            </div>
        </HgDialog>
    </div>
</template>

<script>
    export default {
        name: "login",
        data() {
            return {
                username: '',
                password: '',
                loading: false,
                forget: false,
                UserLoading: false,
                addUser: false,
                userAdd: {
                    username: '',
                    password: '',
                },
            }
        },
        created() {
            let login_info = localStorage.getItem('login_info');
            if (login_info) {
                this.forget = true;
                try {
                    let str = atob(login_info);
                    let base = decodeURIComponent(str);
                    let obj = JSON.parse(base);
                    this.username = obj.username;
                    this.password = obj.password;
                } catch (e) {
                    console.log(e);
                }
            }
        },
        mounted() {
            this.$refs.loginBtn.focus();
        },
        watch: {
            password(val) {
                let temp = "";
                for (let i = 0; i < val.length; i++) {
                    if (val.charCodeAt(i) > 0 && val.charCodeAt(i) < 255) {
                        temp += val.charAt(i);
                    }
                }
                this.password = temp
            }
        },
        methods: {
            /**
             * 登录
             * @return {Promise<void>}
             */
            async login() {
                if (this.username === '') {
                    this.$message.error('请输入用户名');
                    return
                }
                if (this.password === '') {
                    this.$message.error('请输入密码');
                    return
                }
                // if(this.password.length<6 || this.password.length>20){
                //   this.$message.error('密码长度在6~20字符之间');
                //   return
                // }
                // this.loading = true;
                // let [e,data]=await this.$api.login({
                //       username: this.username,
                //         password: this.password
                // })
                // if(e){
                //     this.loading=false
                //     return
                // }

                let [e, data] = await this.$store.dispatch('user/loginApi', {
                    username: this.username,
                    password: this.password
                });
                if (e) {
                    this.loading = false;
                    return
                }
                if (data.code === 200) {
                    this.getStoreTimeInfo();
                    if (this.forget) {
                        let str = JSON.stringify({username: this.username, password: this.password});
                        let base = encodeURIComponent(str);
                        localStorage.setItem('login_info', btoa(base));
                    } else {
                        console.log(2);
                        localStorage.removeItem('login_info')
                    }
                    console.log(3);
                    // this.$store.dispatch('user/userInfoApi');
                    this.getListByUser();
                } else {
                    this.loading = false;
                    this.$message.warning(`${data.msg}`)
                }

            },
            //添加用户
            async adminAddUser() {
                let [e, data] = await this.$api.register(this.userAdd);

                this.loading = true;
                console.log(this.userAdd);
                if (e) return;
                if (data.code === 200) {
                    this.$message.success(data.msg);
                    this.loading = false;
                    this.UserLoading = false;
                    this.addUser = false;
                }
            },
            async getCode() {
                let [e, data] = await this.$api.getCode(this.userAdd);
                if (e) return;
                if (data.code === 200) {
                    this.$message.success('成功');
                }
            },
			//获取用户信息
			async getStoreTimeInfo() {
                let [e, data] = await this.$api.getStoreTimeInfoApi();
                if (e) return;
                if (data.code === 200) {
                   localStorage.setItem('storeInfo',JSON.stringify(data.data))
                }
            },
            async createUser() {
                this.addUser = true;
            },
            /**
             * 登录后获取该用户路由列表
             * @return {Promise<void>}
             */
            async getListByUser() {
                let [e, data] = await this.$store.dispatch('layout/getListByUserApi');
                this.loading = false;
                this.$router.replace('/')
            },
        }
    }
</script>

<style scoped lang="less">
    @main-color: #5F9D9D;
    .login-containers {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        //background-image: url("./../../assets/login-bc.jpg");
        background-size: cover;
        background-repeat: no-repeat;
    }

    .login-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .login-content {
            width: 500px;
            height: 560px;
            padding: 20px;
            box-sizing: border-box;
            border-radius: 10px;
            box-shadow: 0 10px 30px 0 rgba(5, 83, 115, 0.25);
            background-color: rgba(255, 255, 255, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;

            .login-logo {
                width: 140px;
                height: 80px;
                object-fit: contain;
            }

            .login-title {
                height: 70px;
                line-height: 70px;
                font-size: 26px;
                font-weight: 500;
                letter-spacing: 2px;
                color: rgba(32, 24, 24, 1);
            }

            .input-row {
                width: 370px;
                height: 44px;
                margin: 10px 0;
                background: rgba(0, 0, 0, 0);
                border: 1px solid rgba(200, 205, 226, 0.7);
                /* opacity:0.87;*/
                border-radius: 22px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .icon-content {
                    width: 32px;
                    height: 32px;
                    margin: 0 8px;
                    border-radius: 100%;
                    background-color: #DCF7FC;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .iconfont {
                        color: @main-color;
                        font-size: 18px;
                    }
                }

                .input-row-label {
                    font-size: 14px;
                    font-weight: 400;
                    width: 80px;
                    margin-left: 16px;
                    color: #3B4456;
                }

                input {
                    border: none;
                    outline: none;
                    height: 40px;
                    width: 180px;
                    text-align: right;
                    font-size: 14px;
                    font-weight: 400;
                }
            }

            .login-btn {
                text-align: center;
                color: white;
                cursor: pointer;
                width: 370px;
                height: 44px;
                line-height: 44px;
                background: @main-color;
                border-radius: 22px;
                font-size: 16px;
                font-weight: bold;
                outline: none;
            }

            .icon-iconfont-jiaoshi, .icon-mima {
                height: 40px;
                line-height: 40px;
                font-size: 18px;
                text-align: center;
                margin: auto;
            }
        }
    }

    .password-forget {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 370px;
        margin: 30px 0;

        .select-content {
            width: 14px;
            height: 14px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(182, 197, 208, 1);
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;

            .icon-duihao {
                color: #fff;
                font-size: 10px;
            }
        }

        .select-content-text {
            font-size: 14px;
            font-weight: 400;
            color: rgba(147, 160, 183, 1);
        }

        .active {
            background-color: @main-color;
            border-color: @main-color;
        }
    }
	.gov{
		position: absolute;
		left: 0;
		bottom: 20px;
		width: 100%;
		display: flex;
		justify-content: center;
		color: #333;
		font-size: 14px;
		a{
			color: #333;
		}
	}
</style>

